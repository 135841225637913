<template>
  <div class="home-container">
    <van-nav-bar
      title="مايرا ساباقحاناسى"
      @click-left="$router.push('/search')"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        <van-icon name="search" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
      </div>
    </van-nav-bar>

    <!-- 加载中 -->
    <div v-if="loading" class="loading-wrap">
      <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
    </div>
    <template v-if="!loading">
      <div class="bg-white">
        <!-- 搜索区域 -->
        <!-- <div class="search" @click="$router.push({ path: '/search' })">
          <van-icon name="search" />
          <span>ىزدەيتىن مازمۇندى جازىڭىز</span>
        </div> -->
        <!-- 焦点图 -->
        <van-swipe class="my-swipe" indicator-color="white" :autoplay="3000">
          <van-swipe-item
            v-for="(item, index) in swiper"
            :key="index"
            @click="onTapToAd(item)"
          >
            <van-image fit="cover" lazy-load :src="item.image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- <van-image
        @click="$router.push('/vip')"
        class="ershida"
        fit="cover"
        lazy-load
        src="https://mmbiz.qpic.cn/mmbiz_png/ibjLicHT38d12PaSykl281CmwmaEY0hib2NFXDR0NWulGlgdVoxbChYekB8buFfV0TvvISmYVxnQAmMGNJbg48YTw/0?wx_fmt=png"
      /> -->

      <!-- 分类模块 -->
      <Category :cates="cates" />

      <!-- <van-notice-bar
        class="rtl notice"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        text="ونەر مەن دەنساۋلىق ادام بالاسىنا بەرىلگەن ەڭ ۇلكەن بايلىق. قىسقا ومىردە دەنساۋلىقتىڭ قادىرىن ءبىلىپ ءوزىمىز ۇناتقان كاسىپپەن شۇعىلدانۋ اركىمگە بۇيىرا بەرمەيتىن باقىت. ەندەشە ءوز باقتىڭىز ءۇشىن كۇرەسىڭىز."
      /> -->

      <div class="content" v-if="hots.length || topicList.length">
        <template v-if="hots.length">
          <!-- 推荐模块 -->
          <div class="hd-title">
            <span class="name">تانىستىرۋ</span>
          </div>
          <!-- <CourseItem :hots="hots" /> -->
          <GridItem :hots="hots" />
        </template>

        <!-- <template v-if="teachers.length">
          <div class="hd-title mt-10">
            <span class="name">ۇستازدار</span>
          </div>
          <Teacher :teachers="teachers" />
        </template> -->

        <!-- 专题模块 -->
        <template v-if="topicList.length">
          <div class="hd-title mt-10">
            <span class="name">ارناۋلى ايدارشا</span>
            <span class="more" @click="$router.push({ path: '/topicList' })"
              >تولعىراق<van-icon name="arrow-left"
            /></span>
          </div>
          <BigCourseItem :hots="topicList" clickType="topic" />
        </template>
      </div>

      <!-- 按分类显示课程 -->
      <template v-for="item in allData">
        <div class="content" :key="item.title" v-if="item.course.length">
          <div class="hd-title">
            <span class="name">{{ item.name }}</span>
            <span
              class="more"
              @click="$router.push({ path: '/grid', query: { id: item.id } })"
              >تولعىراق<van-icon name="arrow-left"
            /></span>
          </div>

          <GridItem v-if="item.display == 2" :hots="item.course" />
          <ThreeGrid v-else-if="item.display == 3" :hots="item.course" />
          <CourseItem v-else :hots="item.course" />
        </div>
      </template>
      <Follow />
<!--      <Copyright />-->
    </template>
  </div>
</template>

<script>
import Follow from '@/components/Follow'
import Copyright from '@/components/Copyright'
import GridItem from '@/components/Grid-item'
import CourseItem from '@/components/Course-item'
import ThreeGrid from '@/components/Three-Grid'
import BigCourseItem from '@/components/BigCourseItem'
// import Teacher from '@/components/Teacher'
import Category from '@/components/Category'
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import {
  getAllSwiper,
  getAllCategory,
  getRecomList,
  getCoursesByCateList
} from '@/api/home'
import { getAllTopic } from '@/api/topic'
import { getAllTeacher } from '@/api/Teaher'
export default {
  name: 'HomeIndex',
  components: {
    Follow,
    Copyright,
    GridItem,
    CourseItem,
    Category,
    BigCourseItem,
    ThreeGrid
    // Teacher
  },
  data () {
    return {
      loading: true,
      // 搜索关键词
      value: '',
      // 焦点图
      swiper: [],
      // 分类
      cates: [],
      // hots
      hots: [],
      allData: [],
      topicList: [], // 专题列表
      pages: {
        pagenum: 1,
        pagesize: 4,
        total: null
      },
      active: 0,
      teachers: [] // 教师列表
    }
  },
  mounted () {
    document.title = 'مايرا ساباقحاناسى'
    this.getAllSwiper()
    this.getAllCategory()
    this.getRecomList()
    this.gettAllData()
    this.getAllTopic()
    this.getAllTeacher()
    wx.ready(() => {
      initShareInfo({
        title: 'مايرا ساباقحاناسى', // 分享标题
        desc: 'ۇلتتىق ونەرىمىزدىڭ جارشىسى بولايىق، ءبىلىم بىلگەن سايىن قىزىق.', // 分享描述
        imgUrl:
          'http://mmbiz.qpic.cn/mmbiz_png/D5gYF9aqevmSN29jkYhY7yicicHjzfJPK8uLOal7OYyYqj6FzEJ1rqasA28CiaHgWwXnBwaeKkwk4jib2ufial00fKQ/0?wx_fmt=png' // 分享图标
      })
    })
  },

  methods: {
    async getAllSwiper () {
      try {
        const res = await getAllSwiper({
          params: {
            pagenum: this.pages.pagenum,
            pagesize: this.pages.pagesize
          }
        })

        if (res.code === 200 && res.errorCode === 0) {
          this.swiper = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAllTeacher () {
      try {
        const res = await getAllTeacher({
          pagenum: this.pages.pagenum,
          pagesize: 10,
          is_teacher: '1',
          random: 'Y'
        })

        if (res.code === 200 && res.errorCode === 0) {
          this.teachers = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAllCategory () {
      try {
        const res = await getAllCategory()
        if (res.code === 200 && res.errorCode === 0) this.cates = res.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async getRecomList () {
      try {
        const res = await getRecomList({ recommend: 2, pagesize: 4 })
        if (res.code === 200 && res.errorCode === 0) this.hots = res.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async getAllTopic () {
      try {
        const res = await getAllTopic({ pagesize: 4, random: 'Y' })
        if (res.code === 200 && res.errorCode === 0) {
          this.topicList = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async gettAllData () {
      try {
        this.loading = true
        const res = await getCoursesByCateList()
        if (res.code === 200 && res.errorCode === 0) {
          this.allData = res.data.data
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    onTapToAd (item) {
      switch (item.ad_type) {
        case 1:
          this.$router.push({
            name: 'detail',
            params: { parent_id: item.course.id, type: item.course.type }
          })
          break
        case 2:
          window.open(item.ad_url, '_blank')
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  padding-top: 92px;
  padding-bottom: 170px;
  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f3f5;
    color: #999;
    border-radius: 30px;
    font-size: 26px;
    direction: rtl;
    margin: 0 auto 20px;

    span {
      margin-right: 10px;
    }
  }

  .my-swipe {
    height: 300px;
    margin: 20px;
    margin-bottom: 0;
    border-radius: 10px;
    background-color: #fff;
    .van-swipe-item {
      /deep/ .van-image {
        width: 100%;
        height: 300px;
      }
    }
  }

  .content {
    margin: 10px 0;
    direction: rtl;
  }

  .notice {
    margin-top: 10px;
    font-size: 30px;
    text-align: justify;
    /deep/ .van-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
